import React from "react"

function About (props) {
  return(
    <React.Fragment>
      <h1>About Page</h1>
      <button className="btn btn-primary">hey!</button>
    </React.Fragment>
  );
}

export default About
